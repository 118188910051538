<!--非增值税-通用机打发票-->
<template>
    <div class="content-select">
        <el-row class="row-first">
            <el-col :span="12">
                <label>发票代码：</label>
                <span>{{ invoice.invoiceCode }}</span>
            </el-col>
            <el-col :span="12">
                <label>发票号码：</label>
                <span>{{ invoice.invoiceNo }}</span>
            </el-col>
        </el-row>
        <el-row class="row-first">
            <el-col :span="12">
                <label>发票日期：</label>
                <span>{{ invoice.invoiceTime }}</span>
            </el-col>
            <el-col :span="12">
                <label>金额：</label>
                <span>{{ invoice.sumAmount }} 元</span>
            </el-col>
        </el-row>
        <el-row class="row-first">
            <el-col :span="12">
                <label>收票时间：</label>
                <span>{{ invoice.receiveTime }}</span>
            </el-col>
        </el-row>
    </div>
</template>
<script>
    export default {
        name: "StdNVatJdpProps",
        props: {
            invoice: {
                type: Object,
                default: () => {
                    return {
                        invoiceCode: '',
                        invoiceNo: '',
                        invoiceTime: null,
                        sumAmount: '',
                        receiveTime: null
                    };
                }
            }
        },
        data() {
            return {};
        }
    }
</script>
<style>
</style>