<template>
    <div v-if="invoiceImg.visible" class="invoice-img">
        <div v-clickoutside="handleImageClose" class="invoice-close">
            <div class="content">
                <el-image :src="invoiceImg.url">
                </el-image>
            </div>
            <div>
                <el-button type="primary" @click="handleDownLoad">下载</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import Clickoutside from 'element-ui/src/utils/clickoutside';
    import {downloadImage} from "@/service/person-walt";

    export default {
        name: "InvoiceImage",
        directives: {Clickoutside},
        props: {
            invoiceImg: {
                type: Object,
                default: () => {
                    return {
                        visible: false,
                        url: "",
                        imgInvoiceId: "",
                        invoiceCode:"",
                        invoiceNo:""
                    }
                }
            }
        },
        methods: {
            handleImageClose() {
                this.invoiceImg.visible = false
            },
            async handleDownLoad() {
                let nameDownLoad=this.invoiceImg.invoiceNo+"-"+this.invoiceImg.invoiceCode;
                if(!this.invoiceImg.invoiceCode){
                    nameDownLoad=this.invoiceImg.imgInvoiceId;
                }
                const rsp = await downloadImage({invoiceId: this.invoiceImg.imgInvoiceId})
                rsp.headers["file-name"] = nameDownLoad + rsp.headers["file-type"]
                this.downloadFile(rsp)
            }
        }
    }
</script>

<style scoped lang="scss">
    .invoice-close {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    /* 影像图片容器 */
    .invoice-img {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 5;

        .content {
            width: 800px;
            height: 400px;
            text-align: center;
            margin-bottom: 20px;
        }

        .el-image {
            width: 680px;
            height: 397px;
        }
    }
</style>
