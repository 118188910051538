<template>
    <div class="content-wrap">
        <div class="content-select">
            <el-tabs v-model="activeTab">
                <el-tab-pane label="增值税票" name="vat">
                </el-tab-pane>
                <el-tab-pane label="其它发票" name="oth">
                </el-tab-pane>
            </el-tabs>
        </div>
        <vat-invoice-list
                v-show="activeTab == 'vat'"
                @delete-invoice="handleDelete"
                @detail-invoice="handleDetail"
                @image-invoice="handleImage"
                @batch-download="handleDownloadBatch"
                @batch-print="handlePrintBatch"
                @batch-export="handleExportBatch"></vat-invoice-list>
        <oth-invoice-list
                v-show="activeTab == 'oth'"
                @delete-invoice="handleDelete"
                @detail-invoice="handleDetail"
                @image-invoice="handleImage"
                @batch-download="handleDownloadBatch"
                @batch-print="handlePrintBatch"
                @batch-export="handleExportBatch"></oth-invoice-list>
        <!--发票详情-->
        <el-drawer append-to-body title="发票信息"
                   :with-header="false"
                   :visible.sync="invoiceDetailVisible"
                   direction="rtl"
                   size="50%">
            <std-invoice-detail :invoice="invoiceDetail"
                                :data-loading="invoiceDetailLoading"></std-invoice-detail>
        </el-drawer>
        <!--发票打印-->
        <el-dialog append-to-body title=""
                   :visible.sync="printVisible"
                   width="50%"
                   top="10vh"
                   :show-close="true"
                   :close-on-press-escape="false"
                   :beforeClose="handleCancelPrintDialog">
            <template>
                <div class="print-wrap">
                    <div class="dialog-header">
                        <span class="dialog-title">发票打印预览</span>
                    </div>
                    <div id="print" ref="printArea" class="print-page">
                        <div class="img-page" v-for="item in printSelections" :key="item.id">
                            <el-image class="img-content" @error.once="handlePrintLoading(item)"
                                      :src="item" :preview-src-list="printSelections" lazy>
                            </el-image>
                        </div>
                    </div>
                    <div class="print-button">
                        <el-button type="default" @click="handleCancelPrintDialog">取消</el-button>
                        <el-button type="primary" v-print="printObj" @click="printVisible = false"
                                   :disabled="this.printSelections.length == 0">打印
                        </el-button>
                    </div>
                </div>
            </template>
        </el-dialog>
        <!--发票影像-->
        <invoice-image :invoiceImg="invoiceImg">
        </invoice-image>
    </div>
</template>

<script>
    import StdInvoiceDetail from '@/components/invoice/StdInvoiceDetail.vue';
    import VatInvoiceList from './components/VatInvoiceList.vue';
    import OthInvoiceList from './components/OthInvoiceList.vue';
    import InvoiceImage from "./components/InvoiceImage.vue";
    import {
        dateOption,
        tableStyle,
        pagingMethods
    } from "@/util/mixins";
    import {
        cInvoicesExport,
        cInvoicesDownload,
        downloadImage,
        getInvoiceDetailById,
        delInvoiceDetailById,
        getCInvoiceImageById,
        getCInvoiceImagesByIds
    } from "@/service/person-walt";

    export default {
        name: "PersonWallet",
        mixins: [dateOption, tableStyle, pagingMethods],
        components: {
            VatInvoiceList,
            OthInvoiceList,
            StdInvoiceDetail,
            InvoiceImage
        },
        data() {
            return {
                activeTab: 'vat',
                imageLoading: false,
                invoiceImg: {
                    visible: false,
                    url: "",
                    imgInvoiceId: "",
                    invoiceCode: "",
                    invoiceNo: ""
                },
                printSelections: [],
                printVisible: false,
                printObj: {
                    id: "print",
                    extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>,<style> #print { width: 83%;}</style>'
                },
                invoiceDetailVisible: false,
                invoiceDetail: {},
                invoiceDetailLoading: false
            }
        },
        created() {
        },
        methods: {
            // 批量导出
            async handleExportBatch(selection, callback) {
                if (selection.length == 0) {
                    this.toast("请勾选需要导出的信息！", "error");
                    callback();
                    return;
                }
                let ids = selection.map(item => item.id);
                let isVat = this.activeTab == "vat";
                const rsp = await cInvoicesExport({ids: ids, isVat: isVat});
                this.downloadFile(rsp);
                callback();
            },
            // 批量下载
            async handleDownloadBatch(selection, callback) {
                if (selection.length == 0) {
                    this.toast("请勾选需要下载的信息！", "error");
                    callback();
                    return;
                }
                let ids = selection.map(item => item.id);
                const rsp = await cInvoicesDownload(ids);
                this.downloadFile(rsp)
                callback();
            },
            // 发票详情
            handleDetail(row) {
                console.log("发票信息", row);
                const id = row.id;
                this.invoiceDetailVisible = true;
                this.invoiceDetailLoading = true;
                getInvoiceDetailById({invoiceId: id}).then(res => {
                    if (res.success) {
                        this.invoiceDetail = res.data;
                    }
                    this.invoiceDetailLoading = false;
                });
            },
            // 发票影像
            handleImage(row) {
                this.invoiceImg.imgInvoiceId = row.id;
                row.downloadLoading = true;
                this.invoiceImg.url = null;

                getCInvoiceImageById(row.id).then(res => {
                    if (res.success) {
                        row.downloadLoading = false;
                        this.invoiceImg.url = res.data;
                        this.invoiceImg.visible = true;
                        this.invoiceImg.invoiceNo = row.invoiceNo;
                        this.invoiceImg.invoiceCode = row.invoiceCode;
                    }
                });
            },
            //下载影像
            async downloadImage(id) {
                const image = await downloadImage(id)
                if (!image) {
                    this.toast("下载影像服务发生错误！", "error");
                    return;
                }
                let url = window.URL.createObjectURL(new Blob([image.data], {
                    type: "application/x-msdownload"
                }));
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', "发票影像.png");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            // 发票删除
            handleDelete(row, callback) {
                const id = row.id;
                this.$confirm('确定要删除该张发票吗？', '', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    delInvoiceDetailById({invoiceId: id}).then(res => {
                        if (res.success) {
                            callback();
                            this.$message.info('已成功删除发票！');
                        }
                    });
                }).catch(() => {
                    this.$message.info('已取消删除发票！');
                });
            },
            // 批量打印弹窗
            handlePrintBatch(selection, callback) {
                this.printSelections = [];
                if (selection.length == 0) {
                    this.toast("请勾选需要打印的发票", "warning");
                    callback();
                    return;
                }
                let ids = selection.map(item => item.id);
                getCInvoiceImagesByIds(ids).then(res => {
                    callback();
                    if (res.success) {
                        this.printSelections = res.data;
                        this.printVisible = true;
                    }
                })
            },
            //打印图片加载错误
            handlePrintLoading(url) {
                this.printSelections.splice(this.printSelections.indexOf(url), 1);
            },
            //关闭打印弹窗
            handleCancelPrintDialog() {
                this.printSelections = [];
                this.printVisible = false;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/style/select.scss";

    .select-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #e9e9e9;
        padding: 16px 24px;

        .select-items {
            display: inline-flex;

            span {
                color: #3d94ff;
                margin-right: 20px;
                font-weight: 500;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                line-height: 22px;
            }

            img {
                width: 20px;
                height: 20px;
                vertical-align: sub;
                margin-right: 4px;
            }

            span:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .select-title:before {
            content: "|";
            display: inline-block;
            font-size: 16px;
            margin-right: 10px;
            background-color: #333;
        }
    }

    .content-main {
        display: flex;
        flex-direction: column;
        padding: 0 24px;
        justify-content: space-between;

        .main-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .header-title {
                color: #666666;
                font-size: 16px;
            }
        }

        .main-table {
            margin-top: 10px;

            .operate-button {
                .el-button {
                    padding: 0;
                }
            }
        }
    }

    ::v-deep .el-dialog__header {
        display: none;
    }

    ::v-deep .el-dialog__body {
        padding: 24px !important;
    }

    /*打印样式*/
    .print-wrap {
        .dialog-title {
            color: #333333;
            font-size: 24px;
            display: flex;
            justify-content: center;
        }

        .print-page {
            max-height: 500px;
            overflow-y: auto;
            padding: 10px 0px 10px 10px;

            .img-page {
                border-radius: 10px;
                border: 3px dashed #CCCCCC;
                padding: 5px;
                margin-bottom: 10px;

                .img-content {
                    width: 100%;
                    background: #EEEEEE;
                    border: 2px solid #9D5224;
                }
            }
        }

        .print-button {
            text-align: right;
            padding: 10px 10px 8px 0px;
        }
    }
</style>
