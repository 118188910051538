<!--非增值税-火车票-->
<template>
    <div class="content-select">
        <el-row class="row-first">
            <el-col :span="12">
                <label>乘车人：</label>
                <span>{{ invoice.rider }}</span>
            </el-col>
            <el-col :span="12">
                <label>发车时间：</label>
                <span>{{ invoice.setOutDate }}</span>
            </el-col>
        </el-row>
        <el-row class="row-first">
            <el-col :span="12">
                <label>出发站：</label>
                <span>{{ invoice.departure }}</span>
            </el-col>
            <el-col :span="12">
                <label>目的站：</label>
                <span>{{ invoice.destination }}</span>
            </el-col>
        </el-row>
        <el-row class="row-first">
            <el-col :span="12">
                <label>车次号：</label>
                <span>{{ invoice.flight }}</span>
            </el-col>
            <el-col :span="12">
                <label>座位等级：</label>
                <span>{{ invoice.seatClass }}</span>
            </el-col>
        </el-row>
        <el-row class="row-first">
            <el-col :span="12">
                <label>21位码：</label>
                <span>{{ invoice.train21No }}</span>
            </el-col>
            <el-col :span="12">
                <label>票价：</label>
                <span>{{ invoice.sumAmount }} 元</span>
            </el-col>
        </el-row>
        <el-row class="row-first">
            <el-col :span="12">
                <label>收票时间：</label>
                <span>{{ invoice.receiveTime }}</span>
            </el-col>
        </el-row>
    </div>
</template>
<script>
    export default {
        name: "StdNVatHcpProps",
        props: {
            invoice: {
                type: Object,
                default: () => {
                    return {
                        rider: '',
                        setOutDate: '',
                        departure: '',
                        destination: '',
                        flight: '',
                        seatClass: '',
                        train21No: '',
                        sumAmount: null,
                        receiveTime: null
                    };
                }
            }
        },
        data() {
            return {};
        }
    }
</script>
<style>
</style>
